import gardengods from './images/parks/gardengods.jpg'
import pikespeak from './images/parks/pikespeak.jpg'
import royalgorge from './images/parks/royalgorge.jpg'
import rockymountain from './images/parks/rockymountain.jpg'
import kinzuabridge from './images/parks/kinzuabridge.jpg'

export const SliderData = [
    {
        image: gardengods
    },
    {
        image: pikespeak
    },
    {
        image: royalgorge
    },
    {
        image: rockymountain
    },
    {
        image: kinzuabridge
    }

  ];